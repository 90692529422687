import barba from "@barba/core"
import Animations from "./animations"
import Site from "./site"

export default function () {
  this.initialise = function () {
    barba.init({
      debug: true,
      cacheIgnore: ["/search"],
      transitions: [
        {
          name: "new",
          sync: false,
          leave(data) {
            $("nav").removeClass("z-40").addClass("z-60")
            $(data.current.container).addClass("h-screen overflow-hidden")
          },
          enter(data) {
            window.scrollTo({ top: 0, left: 0, behavior: "instant" })
            $(".share-bar").addClass("lg:duration-300 lg:opacity-0")

            var timeline = new gsap.timeline().pause()
            var swipe = $(".swipe-page")
            var duration = 0.8 //0.8

            timeline.to(swipe, { y: "100%", duration: 0, display: "block" })
            timeline.to(swipe, { y: "0", duration: duration, ease: "circ.in" })
            timeline.to(swipe, {
              y: "-100%",
              duration: duration,
              display: "none",
              ease: "circ.out"
            })

            timeline.to(
              data.current.container,
              {
                top: "-100vh",
                duration: duration,
                ease: "circ.in"
              },
              0
            )

            timeline
              .to(
                data.next.container,
                {
                  top: "-100vh",
                  opacity: 1,
                  duration: duration * 1.2
                },
                duration
              )
              .to(data.next.container, {
                top: 0,
                duration: 0
              })

            return timeline.play()
          },
          afterEnter() {
            $("nav").removeClass("z-60").addClass("z-40")
            $(".share-bar")
              .removeClass("lg:opacity-0")
              .addClass("lg:opacity-100")

            new Animations().initialise()
            new Site().initialise()
          }
        }
      ]
    })

    barba.hooks.beforeEnter(({ current, next }) => {
      // if namespace is different (e.g different theme, layout etc) force redirect instead
      if (current.namespace !== next.namespace) {
        barba.force(next.url.href)
      }
    })
  }
}
