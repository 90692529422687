export default function () {
  this.initialise = function () {
    if ($(".print-only").length === 0) {
      $(".js-score-panel").css("opacity", "0")
      $(".js-score-panel").css("transform", "translateY(20%)")
      $(".js-gauge-container").css("transform", "translateX(100%)")
      $(".js-gauge-container").css("opacity", "0")

      $(".js-animate-up").css("opacity", "0")
      $(".js-animate-up").css("transform", "translateY(20%)")

      $(".js-fade-in").css("opacity", "0")

      var controller = new ScrollMagic.Controller()

      new ScrollMagic.Scene({
        offset: 10
      })
        .setClassToggle("nav.fixed", "compact-version")
        .addTo(controller)

      new ScrollMagic.Scene({
        triggerElement: ".js-score-container",
        offset: "-200"
      })
        .on("enter", function () {
          var gaugeTimeline = anime.timeline({
            easing: "easeOutQuart",
            duration: 500
          })

          gaugeTimeline
            .add({
              delay: 1000,
              targets: ".js-score-panel",
              translateY: 0,
              opacity: 1
            })
            .add({
              targets: ".js-gauge-slide-in",
              delay: anime.stagger(250),
              translateX: 0,
              opacity: 1
            })
        })
        .on("leave", function () {})
        .addTo(controller)

      $(".js-trigger").each(function (i, match) {
        let uniqueClassName = "js-animate-trigger-" + i

        $(match).addClass(uniqueClassName)

        new ScrollMagic.Scene({
          triggerElement: "." + uniqueClassName,
          duration: $(match).height(),
          triggerHook: 0.8
        })
          .on("enter", function () {
            anime
              .timeline({
                easing: "easeOutQuart",
                duration: 1500
              })
              .add(
                {
                  targets: "." + uniqueClassName + " .js-animate-up",
                  translateY: 0,
                  opacity: 1,
                  complete: function (anim) {
                    $(".js-trigger-reset").css("overflow", "visible")
                  }
                },
                400
              )
              .add(
                {
                  targets: "." + uniqueClassName + " .js-fade-in",
                  opacity: 1
                },
                300
              )
              .add(
                {
                  duration: 300,
                  targets: "." + uniqueClassName + " .glow",
                  opacity: 1
                },
                200
              )
              .add(
                {
                  duration: 300,
                  targets: "." + uniqueClassName + " .glow-right",
                  opacity: 1
                },
                200
              )
              .add(
                {
                  duration: 300,
                  targets: "." + uniqueClassName + " .glow-bottom",
                  opacity: 1
                },
                200
              )
          })
          .addTo(controller)
      })

      /* Steps animation */

      $(".js-animate-step").css("opacity", "0")
      $(".js-animate-step").css("transform", "translateY(50%)")

      $(".js-animate-steps-trigger").each(function (i, match) {
        let uniqueClassName = "js-animate-steps-" + i

        $(match).addClass(uniqueClassName)

        new ScrollMagic.Scene({
          triggerElement: "." + uniqueClassName,
          duration: $(match).height(),
          triggerHook: 0.9
        })
          .on("enter", function () {
            anime
              .timeline({
                easing: "easeOutQuart",
                duration: 500
              })
              .add({
                targets: "." + uniqueClassName + " .js-animate-step",
                duration: 1000,
                delay: anime.stagger(200),
                translateY: 0,
                opacity: 1
              })
          })
          .addTo(controller)
      })

      /* Image switcher animation */
      $(
        ".js-animate-image-switch-trigger .js-animate-image-switch-target"
      ).each(function (i, match) {
        if (i > 0) $(match).css("opacity", "0")
      })

      $(".js-animate-image-switch-trigger").each(function (i, match) {
        let uniqueClassName = "js-animate-image-switch-trigger-" + i
        $(match).addClass(uniqueClassName)

        $(match)
          .find(".js-animate-image-switch")
          .each(function (n, trigger) {
            let triggerClassName =
              "." + uniqueClassName + " .js-animate-image-switch-trigger-" + n
            let targetClassName =
              "." + uniqueClassName + " .js-animate-image-switch-target-" + n

            new ScrollMagic.Scene({
              triggerElement: triggerClassName,
              duration: $(trigger).height()
            })
              .on("enter", function () {
                anime
                  .timeline({
                    easing: "easeOutQuart",
                    duration: 1000
                  })
                  .add({
                    targets: targetClassName,
                    opacity: 1
                  })
              })
              .on("leave", function () {
                anime
                  .timeline({
                    easing: "easeOutQuart",
                    duration: 1000
                  })
                  .add({
                    targets: targetClassName,
                    opacity: 0
                  })
              })
              .addTo(controller)
          })
      })
    }
  }
}
