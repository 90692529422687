import PageTransitions from "./initialisers/page-transitions"
import Animations from "./initialisers/animations"
import Site from "./initialisers/site"

new PageTransitions().initialise()
new Animations().initialise()

$(function () {
  new Site().initialise()
})

window.supportsRange = function () {
  var input = document.createElement("input")
  input.setAttribute("type", "range")
  return input.type !== "text"
}

window.validateFormControls = function (parentId, message) {
  let valid = true

  $("#" + parentId + " .message").addClass("hidden")

  $("#" + parentId)
    .find("input")
    .each(function () {
      if (this.checkValidity() === false) {
        valid = false
      }
    })

  if (!valid) {
    $("#" + parentId + " .message")
      .removeClass("hidden")
      .get(0)
      .scrollIntoView()
  }

  return valid
}

window.locationAutocomplete = function () {
  $(function () {
    $(".js-location-autocomplete").each(function () {
      var completer = new google.maps.places.Autocomplete(this, {
        types: ["(cities)"],
        fields: ["address_component"]
      })

      google.maps.event.addListener(completer, "place_changed", function () {
        var chosen = completer.getPlace()

        let address = chosen.address_components
        if (address) {
          let country = address.find(
            (f) => f.types.indexOf("country") !== -1
          ).short_name

          // GB level 1 is England/Scotland/Wales/Northern Ireland
          // GB level 2 is a county
          let stateField =
            country === "GB"
              ? "administrative_area_level_2"
              : "administrative_area_level_1"

          $("input[autocomplete=city]").val(
            address.find((f) => f.types.indexOf("locality") !== -1).short_name
          )
          $("input[autocomplete=state]").val(
            address.find((f) => f.types.indexOf(stateField) !== -1).short_name
          )
          $("input[autocomplete=country]").val(country)
        }
      })
    })
  })
}
