import ClipboardJS from "clipboard"

export default function () {
  this.initialise = function () {
    try {
      var macy = Macy({
        container: ".masonry",
        trueOrder: false,
        waitForImages: false,
        margin: 6,
        columns: 3,
        breakAt: {
          940: 2,
          520: 1
        }
      })
    } catch (any) {
      // swallow
    }

    $(".floating-select select").each(function (i, select) {
      var select = $(select)

      if (select.val() == "") {
        select.parents(".floating-select").addClass("no-value-entered")
      } else {
        select.parents(".floating-select").addClass("value-entered")
      }
    })

    $(".floating-select select").on("change", function (event) {
      let select = $(event.target)

      if (select.val() == "") {
        select.parents(".floating-select").addClass("no-value-entered")
        select.parents(".floating-select").removeClass("value-entered")
      } else {
        select.parents(".floating-select").addClass("value-entered")
        select.parents(".floating-select").removeClass("no-value-entered")
      }
    })

    $("li > span").each(function (i, span) {
      var className = $(span).attr("class")

      $(span).parents("ul").addClass(className)
    })

    $(".js-reset-page").on("click", function () {
      $("input[name=page]").val(1)
    })

    $(".homepage-links").each(function (i, match) {
      var waypoint = new Waypoint({
        element: match,
        offset: "100px",
        handler: function (direction) {
          if (direction === "down")
            $(match)
              .find(".card")
              .each(function (i, card) {
                card.style.animation = "none"
                card.offsetHeight // trigger reflow
                card.style.animation = null
              })
        }
      })
    })

    $("[data-popup-link]").each(function (i, match) {
      $(match).attr("href", "#" + $(match).data("popup-link"))
    })

    $(".js-prevent-double-submit").on("submit", function (event) {
      var isDisabled = $(this).find("[aria-disabled]").length > 0

      if (isDisabled) {
        event.preventDefault()
        return
      }

      // only disable if all the fields pass validation!
      if ($(this).find(":invalid").length === 0) {
        $(".js-prevent-double-submit input[type=submit").attr(
          "aria-disabled",
          true
        )
      }
    })

    $(".waypoint-popup").each(function (i, match) {
      var waypoint = new Waypoint({
        element: match,
        offset: "-50%",
        handler: function (direction) {
          if ($(".delayed-popup").length == 0) {
            return
          }
          if (direction === "down") {
            $(".delayed-popup").get(0).dispatchEvent(new CustomEvent("open-me"))

            waypoint.disable()
          }
        }
      })
    })

    $(".waypoint-popup-bottom").each(function (i, match) {
      var waypoint = new Waypoint({
        element: match,
        offset: "75%",
        handler: function (direction) {
          if ($(".delayed-popup-bottom").length == 0) {
            return
          }

          if (
            direction === "down" &&
            !$(".delayed-popup-bottom").hasClass("js-already-hidden")
          ) {
            $(".delayed-popup-bottom")
              .get(0)
              .dispatchEvent(new CustomEvent("open-me"))

            waypoint.disable()
          }
        }
      })
    })

    $(".waypoint-close-gap").each(function (i, match) {
      var waypoint = new Waypoint({
        element: match,
        handler: function (direction) {
          if (direction === "down") {
            $(".animate-close-gap").addClass("close-gap")
          } else {
            $(".animate-close-gap").removeClass("close-gap")
          }
        }
      })
    })

    if ($(".glider").length > 0) {
      let glider = new Glider(document.querySelector(".glider"), {
        slidesToShow: 1,
        dots: ".dots",
        scrollLock: true,
        draggable: true
      })

      $(".glider-slide").removeClass("hidden")

      function autoplay(slider, miliseconds) {
        const slidesCount = slider.track.childElementCount
        let slideTimeout = null
        let nextIndex = 1

        function slide() {
          slideTimeout = setTimeout(function () {
            if (nextIndex >= slidesCount) {
              nextIndex = 0
            }
            slider.scrollItem(nextIndex++)
          }, miliseconds)
        }

        slider.ele.addEventListener("glider-animated", function () {
          window.clearInterval(slideTimeout)
          slide()
        })

        slide()
      }

      autoplay(glider, 5000)
    }

    $(".js-close").on("click", function (event) {
      event.preventDefault()
      let target = $(event.target).data("close-target")

      $(target).addClass("hidden")
      $(target).addClass("js-already-hidden")
    })

    // Refer a friend, client side validation and spinner magic.
    $(".js-referral-form").on("submit", function (_evt) {
      // is this a row of empty inputs?
      const isEmpty = function (row) {
        var inputs = $(row).find("input")
        var empties = inputs.filter(function (_ix, ipt) {
          return ($(ipt).val() || "").length === 0
        })

        return empties.length === inputs.length
      }

      // is this a row with at least one empty input?
      const isIncomplete = function (row) {
        var inputs = $(row).find("input")
        var filled = inputs.filter(function (_ix, ipt) {
          return ($(ipt).val() || "").length > 0
        })

        return filled.length < inputs.length
      }

      // clear everything then recompute
      var list = $("div.js-rf-email-list")
      $(list).find(".js-rf-validation-min").addClass("hidden")
      $(list).find(".js-rf-validation").addClass("hidden")
      $(list).find("input").removeClass("border-red-500 rounded")

      var oneNeeded = true // make false if any non-empty found
      var anyIncomplete = false // make true if any incomplete found

      $(list)
        .find(".js-rf-list-item")
        .each(function (_ix, item) {
          var empty = isEmpty(item)
          var incomplete = isIncomplete(item)
          if (incomplete && !empty) {
            $(item).find(".js-rf-validation").removeClass("hidden")
            $(item).find("input").addClass("border-red-500 rounded")
          }

          oneNeeded &= empty
          anyIncomplete |= incomplete && !empty
        })

      if (oneNeeded) {
        $(list).find(".js-rf-validation-min").removeClass("hidden")
      }

      if (oneNeeded || anyIncomplete) {
        return false // block submission
      }

      $(".js-referral-submit").addClass("hidden").attr("disabled", true)
      $(".js-referral-spinner").removeClass("hidden")
      return true // let the flow continue
    })

    $(".scroller .js-scroll-left").on("click", function () {
      var container = $(this).parents(".scroller").find("ul").get(0)
      if (container) {
        container.scrollBy({ left: -250, behavior: "smooth" })
      }
    })

    $(".scroller .js-scroll-right").on("click", function () {
      var container = $(this).parents(".scroller").find("ul").get(0)
      if (container) {
        container.scrollBy({ left: 250, behavior: "smooth" })
      }
    })

    $(".smooth-scroll").on("click", function (event) {
      event.preventDefault()

      document.querySelector(this.getAttribute("href")).scrollIntoView({
        behavior: "smooth"
      })
    })

    // member discounts slice
    $(".js-discount-reveal").on("click", function (e) {
      e.preventDefault()
      let t = $(e.target)
      let p = t.parents(".js-discount-tile")
      p.find(".js-discount-reveal").addClass("hidden")
      p.find(".js-discount-text").removeClass("hidden")
      p.find(".js-discount-claim").removeClass("hidden")
      return false
    })

    $(".score-gauge").each(function () {
      let score = parseInt($(this).data("score"))
      let initialScore = score
      let min = parseInt($(this).data("min"))
      let max = parseInt($(this).data("max"))
      let thresholdAmber = parseInt($(this).data("threshold-amber")) || 0
      let thresholdGreen = parseInt($(this).data("threshold-green")) || 0
      let defaultColour = $(this).data("color") || "#B50E7d"
      let startAngle = parseFloat($(this).data("start-angle")) || -90
      let endAngle = parseFloat($(this).data("end-angle")) || -90.01

      let printMode = $(".print-only").length > 0

      if (!printMode && score !== 0 && $(this).data("delay")) {
        initialScore = 0
      }

      let gauge = new Gauge(this, {
        min: min,
        max: max,
        dialStartAngle: startAngle,
        dialEndAngle: endAngle,
        value: initialScore,
        showValue: false,
        color: function (value) {
          if (!thresholdAmber && !thresholdGreen) {
            return defaultColour
          }

          if (value < thresholdAmber) {
            return "#ea324c"
          } else if (value < thresholdGreen) {
            return "#f2ac42"
          } else {
            return "#00b38a"
          }
        }
      })

      if (!printMode && score != 0 && initialScore === 0) {
        setTimeout(() => {
          gauge.setValueAnimated(score, 1)

          let timer = null

          let currentScore = 0
          let eachInterval = score / 100

          timer = setInterval(() => {
            currentScore += eachInterval

            if (currentScore > score) {
              currentScore = score
              clearInterval(timer)
            }

            $(this)
              .parent(".st")
              .find(".gauge-total")
              .text(parseInt(currentScore))
          }, 10)
        }, 1000)
      }
    })

    let event = new CustomEvent("gauges-rendered")
    document.dispatchEvent(event)

    let handler = function (event) {
      let parent = $(this).parents(".question")
      let value = Math.round(event.target.value)
      $(this).val(value)
      let options = $(this).data("options").split(",")

      let parsedValue = options[value]

      let match = $(this)
        .parents(".question")
        .find(`input[value='${parsedValue}']`)

      match.prop("checked", true)

      let allLabels = parent.find(".js-survey-slider-label")
      let matchingLabel = $(parent.find(".js-survey-slider-label").get(value))

      allLabels.removeClass("active")
      matchingLabel.addClass("active")
    }

    $(".js-survey-slider").on("change", handler)
    $(".js-survey-slider").on("click", handler)

    $(".js-survey-slider-label").on("click", function (event) {
      let optionIndex = parseInt($(this).data("index"))
      let slider = $(this).parents(".question").find(".js-survey-slider")
      slider.val(optionIndex)

      let options = slider.data("options").split(",")
      let parsedValue = options[optionIndex]

      let match = $(this)
        .parents(".question")
        .find(`input[value='${parsedValue}']`)

      $(this)
        .parents(".question")
        .find(".js-survey-slider-label")
        .removeClass("active")
      $(this).addClass("active")

      match.prop("checked", true)
    })

    $(".radar-plot").each(function () {
      let scores = $(this).data("scores")
      let keys = scores.map((s) => s.label)
      let values = scores.map((s) => s.score)

      // add first plot again to end
      keys.push(scores[0].label)
      values.push(scores[0].score)

      const radarData = [
        {
          type: "scatterpolar",
          r: values,
          theta: keys,
          fill: "toself",
          line: { shape: "spline" }
        }
      ]
      const radarLayout = {
        polar: {
          radialaxis: {
            visible: false,
            showticklabels: false,
            linewidth: 0,
            ticklen: 0,
            range: [0, 25]
          },
          angularaxis: {
            visible: true,
            rotation: 135,
            direction: "clockwise",
            gridwidth: 2,
            gridcolor: "#fff",
            showline: false,
            showticklabels: false,
            ticks: ""
          }
        },
        showlegend: false,
        margin: {
          b: 0,
          t: 0,
          l: 0,
          r: 0
        },
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        colorway: ["#B50E7D"]
      }

      let plot = Plotly.newPlot($(this).get(0), radarData, radarLayout, {
        staticPlot: true,
        responsive: true
      })

      Plotly.Plots.resize($(this).get(0))
    })

    $("#js-trigger-loading").each(function () {
      let action = $(this).data("url")

      let headers = new Headers()
      headers.set("Accept", "application/json")

      fetch(action, {
        headers: headers
      })
        .then((r) => r.json())
        .then((j) => (window.location = j.go))
    })

    new ClipboardJS(".copy-to-clipboard")

    $(".swiper").each(function () {
      var swiper = new Swiper(".swiper", {
        speed: 500
      })

      $(".next-slide").on("click", () => {
        swiper.slideNext()
      })

      $(".previous-slide").on("click", () => {
        swiper.slidePrev()
      })

      swiper.on("progress", (swp, howFar) => {
        let count = swp.slides.length
        let completedAlready = 1 / count
        let percentage = Math.min(
          completedAlready + howFar * ((count - 1) / count),
          100
        )

        $(swiper.el)
          .find(".progress-bar")
          .css({ width: `${percentage * 100}%` })
      })

      swiper.on("slideChange", (swp) => {
        $(swiper.el)
          .find(".current-slide")
          .text(swp.activeIndex + 1)
      })

      swiper.slideTo(0)
    })
  }
}
